export const setFlashNotification = (message, type) => {
  const container = $("#flash_notifications");

  const newNotification = $(`
    <div id="flash_${type}">
        ${message}
    </div>
  `);

  container.append(newNotification);

  $("html, body").animate({
    scrollTop: 0
  }, "fast")
}
