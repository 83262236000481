

import { Controller } from 'stimulus';

export default class extends Controller {
  // static targets = ['viewPort'];

  connect() {
    this.initiateInstance();
  }

  disconnect() {
    this.destroyInstance();
  }

  initiateInstance() {
    this.choiceInstanceList = [];
    this.autocompleteInstanceList = [];
    this.modalInstanceList = [];
    this.sliderInstanceList = [];
    this.materialboxedInstanceList = [];
    M.updateTextFields();

    this.slidOutMenu  = document.querySelector('#slide-out');
    this.choiceSelect = document.querySelectorAll('.js-choice');
    this.autocomplete = document.querySelectorAll('.autocomplete');
    this.datePikers   = document.querySelectorAll('.datepicker-date');
    this.modal        = document.querySelectorAll('.modal');
    this.slider       = document.querySelectorAll('.slider');
    this.materialboxed = document.querySelectorAll('.materialboxed');

    if (this.slidOutMenu) { this.slideOutMenuInstance = new M.Sidenav(this.slidOutMenu); }

    if (this.choiceSelect) { this.choiceSelect.forEach((element, index, array) => {
        let options = { shouldSort: false };
        if (element.multiple) options.removeItemButton = true;
        this.choiceInstanceList.push(new Choices(element, options));
      }, this);
    };

    if (this.autocomplete) { this.autocomplete.forEach((element, index, array) => {
        this.autocompleteInstanceList.push(M.Autocomplete.init(element,
          { data: JSON.parse(element.dataset.listNames) }
        ));
      }, this);
    }

    this.datePickerinstances = M.Datepicker.init(this.datePikers, { showClearBtn: true,
                                                                    autoClose: true,
                                                                    yearRange: 10, });

    if (this.modal) { this.modal.forEach((element, index, array) => {
        this.modalInstanceList.push(M.Modal.init(element, {}));
      }, this);
    }

    if (this.slider) { this.slider.forEach((element, index, array) => {
        this.sliderInstanceList.push(M.Slider.init(element, {}));
      }, this);
    }

    if (this.materialboxed) { this.materialboxed.forEach((element, index, array) => {
        this.materialboxedInstanceList.push(M.Materialbox.init(element, {}));
      }, this);
    }
  }

  destroyInstance() {
    if (this.slideOutMenuInstance) this.slideOutMenuInstance.destroy();
    if (this.choiceInstanceList.length > 0) {
      this.choiceInstanceList.forEach((element) => { element.destroy(); });
    };
    if (this.autocompleteInstanceList.length > 0) {
      this.autocompleteInstanceList.forEach((element) => { element.destroy(); });
    };
    if (this.datePickerinstances.length > 0) {
      this.datePickerinstances.forEach((element) => { element.destroy(); });
    };

    if (this.modalInstanceList.length > 0) {
      this.modalInstanceList.forEach((element) => { element.destroy(); });
    };

    if (this.sliderInstanceList.length > 0) {
      this.sliderInstanceList.forEach((element) => { element.destroy(); });
    };

    if (this.materialboxedInstanceList.length > 0) {
      this.materialboxedInstanceList.forEach((element) => { element.destroy(); });
    };
  }


  hiddenForClient(event) {
    let status = 'visibility_off';
    let hidden = true;
    let elements = document.getElementsByClassName('hidden_for_client');

    if (event.currentTarget.innerHTML == 'visibility_off') {
      status = 'visibility';
      hidden = false;
    }

    event.currentTarget.innerHTML = status;
    Array.from(elements).forEach((element) => {
      element.hidden = hidden;
    });
  }
};
