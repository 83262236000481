import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['size_model'];

  connect() {
    this.timeout = null;
  }

  searchModel() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { this.updateForm(); }, 1000);
  }

  cleanInputs() {
    if (document.getElementById('size_model').value == '') {
      document.getElementById('size_model').value = null;
    }

    if (document.getElementById('cost').value == '') {
      document.getElementById('cost').value = null;
    }

    document.getElementById('brand_name').value = null;
    M.updateTextFields();
  }

  updateForm() {
    let tire_model_name = document.getElementById('model_name').value;
    if (tire_model_name == '') {
      this.cleanInputs(this);
      return;
    };

    const params = { tire_model_name: tire_model_name };

    Rails.ajax({
      context: this,
      type: 'get',
      dataType: 'json',
      url: '/model_tire_sizes/show',
      data: new URLSearchParams(params).toString(),
      success: function (contentModel) {
        if (contentModel.tire_model_name) {
          document.getElementById('model_name').value = contentModel.tire_model_name;

          if (document.getElementById('size_model').value == '') {
            if (contentModel.sizes.length == 1) {
              document.getElementById('size_model').value = contentModel.sizes[0]["size"]["tire_size"];
              document.getElementById('cost').value = contentModel.sizes[0]["cost"];
            } else {
              this.context.cleanInputs();
              this.context.listSizeModel = contentModel.hash_sizes_model;
            }
          }

          if (contentModel.brand_name) {
            if (contentModel.brands.length == 1) {
              document.getElementById('brand_name').value = contentModel.brand_name;
            } else {
              this.context.cleanInputs();
              this.context.listBrandModel = contentModel.hash_brands_model;
            }
          }

        } else {
          this.context.cleanInputs();
        }

        this.context.initiateInstance(this.context);
        M.updateTextFields();
      },
    });
  }

  initiateInstance(tis) {

    this.autocompleteInstanceList = [];

    this.autocomplete_size_model = document.querySelectorAll('#size_model');
    this.autocomplete_brand_name = document.querySelectorAll('#brand_name');

    if (tis.listSizeModel) {
      this.autocompleteInstanceList.push(M.Autocomplete.init(this.autocomplete_size_model,
          { data: tis.listSizeModel }));
    }

    if (tis.listBrandModel) {
      this.autocompleteInstanceList.push(M.Autocomplete.init(this.autocomplete_brand_name,
          { data: tis.listBrandModel }));
    }
  }

  searchCost() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { this.updateCost(); }, 1000);
  }

  updateCost() {
    let model = document.getElementById('model_name').value;
    let size = document.getElementById('size_model').value;
    if (model == '') {
      this.cleanInputs(this);
      return;
    };

    const params = { tire_model_name: model, tire_size: size };

    Rails.ajax({
      context: this,
      type: 'get',
      dataType: 'json',
      url: '/item_costs/show',
      data: new URLSearchParams(params).toString(),
      success: function (contentCost) {

        if (contentCost.cost) {
          document.getElementById('cost').value = contentCost.cost;
        } else {
          document.getElementById('cost').value = '';
        }

        this.context.initiateInstance(this.context);
        M.updateTextFields();
      },
    });
  }
};
