import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['type_order', 'to_location_id'];

  connect() {
    this.type_order_list = document.getElementById('choices_type_order').getElementsByClassName('choices__item choices__item--choice');
    this.hiddeOrDisplayBtnComplete('');
  }

  hiddeOrDisplayBtnComplete(available_complete) {
    if (available_complete && this.type_orderTarget.value == 'sale') {
      document.getElementById("submit_button").style.display = "block";
    } else {
      document.getElementById("submit_button").style.display = "none";
    }
  }

  typeOrdersAvailable() {
    const params = { location_id: this.to_location_idTarget.value };

    Rails.ajax({
      context: this,
      type: 'get',
      dataType: 'json',
      url: '/current_orders/show',
      data: new URLSearchParams(params).toString(),
      success: function (typeAvailable) {
        this.context.hiddeOrDisplayBtnComplete(typeAvailable.available_complete);
      },
    });
  }

};
