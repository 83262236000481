import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    this.slider = M.Slider.getInstance(document.querySelector('.slider'));
  }

  prev() {
    this.slider.prev();
  }

  next() {
    this.slider.next();
  }
};
